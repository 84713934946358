// open hamburger navbar
export function openHamburgerMenu() {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector("#nav-tabs");

    hamburger.addEventListener("click", function () {
        hamburger.classList.toggle("is-active");
        navMenu.classList.toggle("nav-active");
    });
}

// closes hamburger navbar
export function closeHamburgerMenu() {
    const items = document.querySelectorAll('.nav-tab');
    const navMenu = document.getElementById('nav-tabs');

    const hamburger = document.querySelector(".hamburger");

    items.forEach(item => {
        item.addEventListener("click", function () {
            hamburger.classList.remove("is-active");
            navMenu.classList.remove("nav-active");
        });
    });
}
