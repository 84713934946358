// function to slide in items as they appear in viewport

// mobile version
export function slideInMobile() {
    // items to slide in
    const items = document.querySelectorAll(
        '#mission-content, #apps-content, #our-story-content'
    );

    // const to check if item is in viewport
    const isInViewport = el => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom - 300 <=
            (window.innerHeight || document.documentElement.clientHeight)
        );
    };

    // if item is in viewpoint show item
    const run = () =>
        items.forEach(item => {
            if (isInViewport(item)) {
                item.classList.add('show');
            }
        });

    // events
    window.addEventListener('load', run);
    window.addEventListener('resize', run);
    window.addEventListener('scroll', run);
}

// desktop + larger screen version
export function slideInDesktop() {
    // items to slide in
    const items = document.querySelectorAll(
        '#mission-content, #apps-content, #our-story-content'
    );


    // const to check if item is in viewport
    const isInViewport = el => {

        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom - 200 <=
            (window.innerHeight || document.documentElement.clientHeight)
        );
    };

    // if item is in viewpoint show item
    const run = () =>
        items.forEach(item => {
            if (isInViewport(item)) {
                item.classList.add('show');
            }
        });

    // events
    window.addEventListener('load', run);
    window.addEventListener('resize', run);
    window.addEventListener('scroll', run);
}
