import {closeHamburgerMenu, openHamburgerMenu} from "./nav";
import {slideInDesktop, slideInMobile} from "./slideIn";


// add "show" class to image to slide in
const mockupImg = document.getElementById("welcome-content-img")
mockupImg.classList.add("show")


// handles sliding in items as they appear in viewport
if (window.matchMedia( "(max-width: 1023px)").matches) {
    slideInMobile()
}else{
    slideInDesktop()
}

// handles opening hamburger menu
openHamburgerMenu()

// handles closing hamburger menu
closeHamburgerMenu()

